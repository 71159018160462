<template>
  <div id="service" class="content">
    <div class="search-service">
      <div class="container service-of">
        <span class="befor-title">บริการของ</span>
        <span class="title-inside">{{ serviceName }}</span>
      </div>
    </div>
    <div class="container">
      <div class="link-forward">
        <router-link :to="{ name: 'home' }">
          <span>Home</span>
        </router-link>
        <router-link :to="{ name: 'service' }">
          <span>Service</span>
        </router-link>
      </div>
      <p class="count-search">
        <span>
          ผลลัพธ์การค้นหา
          <span class="active">{{ total }}</span> ศูนย์บริการ
        </span>
      </p>
    </div>
    <div v-loading="loading" class="inside-page">
      <div class="container">
        <el-row :gutter="30" class="set-row">
          <el-col
            :span="24"
            :sm="12"
            :md="8"
            v-for="(dataServices, index) in clinics"
            :key="index"
            class="mt-1"
          >
            <cardService
              :dataService="dataServices"
              :serviceId="serviceId"
              :typeclinics="typeclinics"
            />
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scope>
.count-search {
  padding-top: 20px;
  font-size: 26px;
  font-weight: 300;
  color: #353448;
  margin: 0 0 20px;

  .active {
    font-size: 25px;
    font-weight: 500;
    color: #9e76b4;
  }
}
</style>
<script>
import cardService from "@/components/cardService";
import { HTTP } from "@/service/axios.js";

export default {
  components: {
    cardService,
  },
  props: {
    routeParams: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      serviceId: "",
      serviceName: "",
      clinics: [],
      loading: true,
      typeclinics: "clinics",
      total: "",
    };
  },
  mounted() {
    this.setParams();
    this.fetchServiceById();
    this.fetchClinics();
  },
  methods: {
    setParams() {
      if (this.routeParams != null) {
        this.serviceId = this.routeParams.serviceId;
      }
    },
    fetchClinics() {
      HTTP.get(`clinics/${this.serviceId}`)
        .then((res) => {
          if (res.data.success) {
            this.clinics = res.data.obj;
            this.total = res.data.count;
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("fetchService error", e);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        });
    },
    fetchServiceById() {
      HTTP.get(`service/${this.serviceId}`).then((res) => {
        if (res.data.success) {
          this.serviceName = res.data.obj.nameTH;
        }
      });
    },
  },
};
</script>
<style scoped>
.link-forward {
  margin-top: 20px;
}
</style>